@import "compass";
@import "compass/support";

@import "mixin";

/* ======================================================================
 p_top
====================================================================== */
.p_top {
  .main_visual {
    .main_visual_inner {
      .slider_wrap {
        .item {
          &.item_0 {
            background: url(../img/top/main_visual_item_cam.jpg);
            background-size: cover;

            @include mq_max_768 {
              background: url(../img/top/sp/bg.png);
              background-size: cover;
              background-position: center center;
            }


            @include mq_max_320 {
              align-items: normal !important;
              flex-direction: initial !important;
            }


            .item_content {
              &._pt1 {
                display: block;
                margin: 120px auto 0;
                width: 700px;
                position: relative;
                z-index: 3;
                top: initial;
                left: initial;
                opacity: 1;

                @include mq_max_768 {
                  margin: 0;
                  width: 100%;
                  padding: 0 4.6875%;
                }


                &:before {
                  content: url(../img/top/img02.png);
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  z-index: 2;

                  @include transform(translate(-50%, -50%));
                }

                .item_content_inner {
                  @include mq_max_320 {
                    margin: 150px 0 0;
                  }
                }
              }

              .item_content_inner {
                background: #fff !important;
                border-radius: 10px;

                @include mq_max_768 {
                  padding: 25px 20px !important;
                }


                @include mq_max_320 {
                  padding: 15px !important;
                }


                > .inner {
                  width: initial;
                  position: relative;

                  .h1 {
                    line-height: 1;
                    padding: 0;
                    position: relative;

                    @include mq_max_768 {
                      margin: 0 10px 25px;
                    }


                    > img {
                      margin: 0 auto;
                    }
                  }

                  .ttlwrap {
                    position: relative;
                    width: 425px;
                    margin: 45px auto 0;
                    z-index: 2;

                    @include mq_max_768 {
                      width: 100%;
                      margin: 0 auto;
                    }


                    .center {
                      font-size: 20px;
                      color: #fff;
                      background: #5a5249;
                      padding: 13px 0;
                      width: 100%;
                      text-align: center;
                      letter-spacing: .05em;
                      line-height: 1;

                      @include mq_max_768 {
                        font-size: 14px;
                        padding: 7px 0;
                      }
                    }

                    > .inner {
                      width: 100%;
                      padding: 20px 0 0;
                      background: #fff;

                      @include mq_max_768 {
                        padding: 15px 0 0;
                      }


                      > .txt1 {
                        display: -webkit-flex;
                        display: -ms-flex;
                        display: flex;
                        align-items: center;

                        @include justify-content(center);
                        @include mq_max_768 {
                          display: block;
                        }


                        > span {
                          display: inline-block;
                        }

                        > .tag {
                          font-size: 16px;
                          line-height: 1;
                          color: #5a5249;
                          letter-spacing: .05em;
                          background: #ffe225;
                          border-radius: 30px;
                          padding: 8px 25px;

                          @include mq_max_768 {
                            display: flex;
                            height: 25px;
                            width: 75px;

                            // border-radius: 50%;
                            font-size: 11px;
                            font-weight: bold;
                            letter-spacing: .05em;
                            padding: 0;
                            line-height: 1.1;
                            margin: 0 auto 10px;

                            @include justify-content(center);
                            @include align-items(center);
                          }
                        }

                        > .hiniti {
                          margin: 0 0 0 15px;
                          color: #5a5249;
                          font-size: 23px;
                          letter-spacing: .03em;

                          @include mq_max_768 {
                            font-size: 20px;
                            margin: 0;
                          }


                          > small {
                            font-size: 18px;

                            @include mq_max_768 {
                              font-size: 12px;
                            }
                          }
                        }
                      }

                      > .txt2 {
                        font-size: 14px;
                        margin: 10px 0 0;

                        @include mq_max_768 {
                          font-size: 11px;
                          line-height: 1.5;
                          text-align: left;
                        }


                        @include mq_max_320 {
                          display: none;
                        }
                      }
                    }
                  }

                  .btn_link {
                    @include mq_max_320 {
                      margin: 15px auto 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ======================================================================
 general
====================================================================== */
section.general {
  background: url(../img/top/bg.png) no-repeat center;
  background-size: cover;
  padding: 70px 0 320px 0;
  display: none;

  @include mq_max_767 {
    padding: 40px 0;
  }


  .inner {
    //width: 100%;
    //max-width: 1100px;

    p {
      &.txt {
        &._lh_high {
          @include mq_max_767 {
            text-align: left;
          }
        }
      }
    }

    .js_tab_wrap {
      margin-top: 40px;

      ul.scroll_tabs_theme_light {
        height: 60px;
        margin-bottom: -4px;

        @include mq_max_767 {
          height: 67px;
          margin-bottom: 0;
        }


        .scroll_tab_inner {
          height: auto;
          margin: 0 !important;

          li {
            &.js_tab_btn {
              display: inline-block;
              padding: 10px 0;
              width: 24.25%;
              margin-right: 1%;
              background: #94C74C;
              vertical-align: bottom;
              text-align: center;
              font-weight: bold;
              font-size: 16px;
              cursor: pointer;
              border-top-right-radius: 5px;
              border-top-left-radius: 5px;
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
              color: #fff;

              @include mq_max_767 {
                width: 25%;
                margin-right: 0;
                padding: 17px 0;
              }


              &._tab_current {
                color: #fff;
              }

              &.scroll_tab_last {
                margin-right: 0;
              }
            }

            &:last-child {
              margin-right: 0;
            }

            &:nth-child(3) {
              background: #58AFE3;
            }

            &:nth-child(4) {
              background: #947351;
            }

            &:nth-child(5) {
              background: #DA6B08;
            }
          }
        }
      }

      .panels {
        border: #5A5249 1px solid;
        background: #fff;
        padding: 30px;
        position: relative;
        z-index: 5;
        margin-top: 0;

        @include mq_max_767 {
          padding: 15px 10px;
        }


        .js_tab_contents {
          border: 0;
          padding: 0;
          margin: 0;

          ul.inner {
            max-width: 100%;
            width: 100%;

            @include flex_fltc_03;

            @include mq_max_767 {
              moz-justify-content: inherit;
              -ms-justify-content: inherit;
              justify-content: space-between;
            }


            li {
              border: 1px solid #5A5249;
              border-radius: 6px;
              max-width: 250px;
              width: 24.08478%;
              box-sizing: border-box;
              padding: 0px 32px;
              position: relative;
              margin-bottom: 20px;
              margin-right: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 56px;

              @include mq_max_767 {
                max-width: 100%;
                width: 49%;
                margin-right: 0;
                margin-bottom: 10px;
              }


              &:nth-child(4n) {
                margin-right: 0;
              }

              &::before {
                content: '';
                display: block;
                background: url(../img/top/arrow.png) no-repeat center;
                position: absolute;
                right: 12px;
                width: 14px;
                height: 24px;
              }

              a.wrap {
                .knowledge_list_ttl {
                  p {
                    &.ttl {
                      font-size: 14px;
                      color: #5A5249;
                      line-height: 1.2;

                      @include mq_max_767 {
                        font-size: 12px;
                        line-height: 1.3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .general_links {
      margin-top: 50px;

      @include mq_max_767 {
        margin-top: 30px;
      }
    }
  }
}

/* ======================================================================
 u
====================================================================== */
.u_pc {
  @include mq_max_768 {
    display: none;
  }
}

.u_sp {
  display: none;

  @include mq_max_768 {
    display: block;
  }
}

/* ======================================================================
 p_top
====================================================================== */
/* ======================================================================
 p_top
====================================================================== */
/* ======================================================================
 p_top
====================================================================== */